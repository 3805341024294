<template>
  <div class="field-wrapper" v-bind:class="{ focus: isFocused || showLabel }">

 <label  v-if="isRequired" class="error-label" :class="inputLabelClass"> Required </label>
 


    <div class="labels"  v-bind:class="{ error: showError }">
      <!-- <label class="placeholder" :class="inputLabelClass"> {{fieldLabel}} </label> -->
    
    


     <label v-if="showError" class="error-label1">{{ placeholder }}  <span  v-if="InvalidInput"> (  {{
      invalidInputMessage
    }}) </span> </label>
       
     
    </div>



    <div  class="textarea-input-wrapper" v-bind:class="{ error: showError, required: alert} " >
      
      <span class="fieldset-bg">
        <span
          class="fieldset-bg__alert"
          v-bind:class="{ 'fieldset-bg__alert--visible': showError }"
          v-if="isRequired"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="email-error"
            class=""
          >
            <path
              d="M4 8l1.5 1.5L4 11 2.5 9.5 4 8zm1-7v6H3V1h2z"
              fill="#FFF"
            ></path>
          </svg>
        </span>
      </span>
   
      <textarea
        v-if="type === 'textarea'"
        max="1000"
        min="10"
        v-bind:placeholder="placeholder"
        @focus="onInputFocus"
        @blur="onInputBlur"
        @focusout="outInputFocus"
        v-on:input="onInputChange"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />

    </div>


 
  </div>
</template>

<script>

import "vue3-tel-input/dist/vue3-tel-input.css";

export default {
  name: "InputComponent",

  props: [
    "name",
    "type",
    "required",
    "modelValue",
    "fieldLabel",
    "placeholder",
    "invalidInputMessage",
  ],
  components: {
   
  },
  data() {
    return {
      isFocused: false,
      showLabel: false,
      showError: false,
      InvalidInput: false,
       isRequired: false,
       alert: false,
         
    };
  },
  computed: {
    inputLabelClass() {
      return {
        show: this.showLabel,
        focus: this.isFocused,
      };
    },
  },
  methods: {
    onInputFocus: function() {
      // `this` inside methods points to the Vue instance
      // if (evt.target.value === "" && this.required !== "false") {
      //   this.showError = true;
      //   this.showLabel = false;
      //     this.isRequired= true;
      // }
      // else{
      //     this.isRequired= false;
      // }
    

      this.showError = true;
      this.isFocused = true;
    },

    outInputFocus: function(evt) {


      if(this.required === "true" && evt.target.value === ""){

        this.showError = true;
        this.isFocused = true;
        this.isRequired= true;
            this.alert=true;
      }
      else{
          this.showError = false;
          this.isFocused = false;
            this.isRequired= false;
      }

  

      
    },

    onInputChange: function(evt) {
      

        if (evt.target.value !== "") {
          this.showLabel = true;
          this.isRequired= false;
        } else {
          this.showLabel = false;
        }
      
 
       
    },
    onInputBlur: function(evt) {
      // `this` inside methods points to the Vue instance
      if (evt.target.value == "" && this.required !== undefined) {
        this.showError = true;
      }
      this.isFocused = false;
    },
  },
};
</script>
