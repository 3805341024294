<template>
  <div class="m-fieldset-toggle boolean">
    <label for="opt_in_for_newsletter">{{ label }}</label>
    <input id="opt_in_for_newsletter" @click="onToggleClick" type="checkbox" />
    <span aria-hidden="true" class="toggle boolean">
      <span class="pip boolean" v-bind:class="{ active: isChecked }"></span>
    </span>
  </div>
</template>

<script>
export default {
  name: "CustomToggle",
  props: ["label"],
  data() {
    return {
      isChecked: true,
    };
  },
  methods: {
    onToggleClick: function() {
      this.isChecked = !this.isChecked;
    },
  },
};
</script>
