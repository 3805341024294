<style></style>

<script>
// import Button from "@/components/Button";

// import Map from "@/assets/images/map.svg";

import ThreeGlobe from "three-globe";
import { WebGLRenderer, Scene } from "three";
// import { Canvas } from "@react-three/fiber";
import {
  PerspectiveCamera,
  AmbientLight,
  DirectionalLight,
  Color,
  Fog,
  // AxesHelper,
  // DirectionalLightHelper,
  // CameraHelper,
  PointLight,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
// import { createGlowMesh } from "three-glow-mesh";
import countries from "../files/globe-data-min.json";
import travelHistory from "../files/my-flights.json";
import airportHistory from "../files/my-airports.json";
var renderer, camera, scene, controls;
let mouseX = 0;
let mouseY = 0;
let windowHalfX = window.innerWidth / 2;
let windowHalfY = window.innerHeight / 2;
var Globe;

export default {
  components: {},

  data() {
    return {};
  },

  methods: {
    // SECTION Initializing core ThreeJS elements
    init: function () {
      // Initialize renderer
      renderer = new WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth / 1.75, window.innerHeight / 1.75);
      // renderer.outputEncoding = THREE.sRGBEncoding;
      let wrapper = document.querySelector(".storeLogo1");
      if (wrapper && wrapper.children.length < 2) {
        wrapper.appendChild(renderer.domElement);
      }

      // Initialize scene, light
      scene = new Scene();
      scene.add(new AmbientLight(0xbbbbbb, 0.3));
      scene.background = new Color(0xf5f5f1);

      // Initialize camera, light
      camera = new PerspectiveCamera();
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      var dLight = new DirectionalLight(0xffffff, 0.8);
      dLight.position.set(-800, 2000, 400);
      camera.add(dLight);

      var dLight1 = new DirectionalLight(0x7982f6, 1);
      dLight1.position.set(-200, 500, 200);
      camera.add(dLight1);

      var dLight2 = new PointLight(0x8566cc, 0.5);
      dLight2.position.set(-200, 500, 200);
      camera.add(dLight2);

      camera.position.z = 260;
      camera.position.x = 0;
      camera.position.y = 0;

      scene.add(camera);

      // Additional effects
      scene.fog = new Fog(0x535ef3, 400, 2000);

      // Helpers
      // const axesHelper = new AxesHelper(800);
      // scene.add(axesHelper);
      // var helper = new DirectionalLightHelper(dLight);
      // scene.add(helper);
      // var helperCamera = new CameraHelper(dLight.shadow.camera);
      // scene.add(helperCamera);

      // Initialize controls
      controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dynamicDampingFactor = 0.01;
      controls.enablePan = false;
      controls.minDistance = 240;
      controls.maxDistance = 290;
      controls.rotateSpeed = 0.8;
      controls.zoomSpeed = 1;
      controls.autoRotate = false;

      controls.minPolarAngle = Math.PI / 3.5;
      controls.maxPolarAngle = Math.PI - Math.PI / 3;

      window.addEventListener("resize", this.onWindowResize, false);
      document.addEventListener("mousemove", this.onMouseMove);
    },

    // SECTION Globe
    initGlobe: function () {
      // Initialize the Globe
      Globe = new ThreeGlobe({
        waitForGlobeReady: true,
        animateIn: true,
      })
        .hexPolygonsData(countries.features)
        .hexPolygonResolution(3)
        .hexPolygonMargin(0.7)
        .showAtmosphere(true)
        .atmosphereColor("#ffffff")
        .atmosphereAltitude(0.25)
        .hexPolygonColor((e) => {
          if (
            ["KGZ", "KOR", "THA", "RUS", "UZB", "IDN", "KAZ", "MYS"].includes(
              e.properties.ISO_A3
            )
          ) {
            return "rgba(255,255,255, 1)";
          } else return "rgba(255,255,255, 0.7)";
        });

      // NOTE Arc animations are followed after the globe enters the scene
      setTimeout(() => {
        Globe.arcsData(travelHistory.flights)
          .arcColor(() => {
            return "#FF4000";
            // return e.status ? "#9cff00" : "#FF4000";
          })
          .arcAltitude((e) => {
            return e.arcAlt;
          })
          .arcStroke((e) => {
            return e.status ? 0.5 : 0.3;
          })
          .arcDashLength(0.9)
          .arcDashGap(4)
          .arcDashAnimateTime(1000)
          .arcsTransitionDuration(1000)
          .arcDashInitialGap((e) => e.order * 1)
          .labelsData(airportHistory.airports)
          .labelColor(() => "#5dc93c")
          .labelDotOrientation((e) => {
            return e.text === "ALA" ? "top" : "right";
          })
          .labelDotRadius(0.3)
          .labelSize((e) => e.size * 2.5)
          .labelText("city")
          .labelResolution(6)
          .labelAltitude(0.01)
          .pointsData(airportHistory.airports)
          .pointColor(() => "#ffffff")
          .pointsMerge(true)
          .pointAltitude(0.07)
          .pointRadius(0.25);
      }, 1000);

      Globe.rotateY(-Math.PI * (4 / 9));
      Globe.rotateZ(-Math.PI / 4.5);
      const globeMaterial = Globe.globeMaterial();
      // globeMaterial.color = new Color(0x3a228a);
      globeMaterial.color = new Color(0x000000);
      globeMaterial.emissive = new Color(0x220038);
      globeMaterial.emissiveIntensity = 0.1;
      globeMaterial.shininess = 0.7;

      // NOTE Cool stuff
      // globeMaterial.wireframe = true;

      scene.add(Globe);
    },

    onMouseMove: function (event) {
      mouseX = event.clientX - windowHalfX;
      mouseY = event.clientY - windowHalfY;
      // console.log("x: " + mouseX + " y: " + mouseY);
    },

    onWindowResize: function () {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      windowHalfX = window.innerWidth / 1.5;
      windowHalfY = window.innerHeight / 1.5;
      renderer.setSize(window.innerWidth / 1.75, window.innerHeight / 1.75);
    },

    animate: function () {
      camera.position.x +=
        Math.abs(mouseX) <= windowHalfX / 2
          ? (mouseX / 2 - camera.position.x) * 0.005
          : 0;
      camera.position.y += (-mouseY / 2 - camera.position.y) * 0.005;
      camera.lookAt(scene.position);
      controls.update();
      renderer.render(scene, camera);
      requestAnimationFrame(this.animate);
    },
  },

  mounted() {
    this.init();
    this.initGlobe();
    this.onWindowResize();
    this.animate();
  },
  name: "MapGlobe",
  props: ["type", "name", "disable"],
};
</script>
